import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import { apiGetLanguages } from "services/SettingServices";

const AVAILABLE_LANGUAGES = ["hy", "en", "fr"];

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(Backend)
  .init({
    lng: localStorage.getItem("language") || "hy",
    fallbackLng: ["hy", "en", "fr"],
    ns: ["translations"],
    defaultNS: "translations",
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    parseMissingKeyHandler: () => " ",
    react: {
      useSuspense: false,
    },
  });

AVAILABLE_LANGUAGES.forEach((lng) => {
  apiGetLanguages(lng).then((res: any) => {
    i18n.addResourceBundle(lng, "translations", res.data.data, true, true);
  });
});

export default i18n;
