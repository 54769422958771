import { useState } from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Collapse from "@mui/material/Collapse";
import ListSubheader from "@mui/material/ListSubheader";
import ListItemLink from "../ListItemLink";
import { makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  colapse: {
    position: "absolute",
    background: "#fff",
    borderRadius: 3,
    display: "none",
    boxShadow: "0 4px 8px 4px rgb(0 0 0 / 20%)",
    zIndex: 100,
  },
  listItems: {
    "&:hover .colapse": { display: "unset" },
    [theme.breakpoints.down(1024)]: {
      width: "50%",
      "& .MuiButtonBase-root": {
        justifyContent: "space-between",
      },
    },
    [theme.breakpoints.down("xs")]: {
      width: "80%",
    },
    [theme.breakpoints.down(400)]: {
      width: "90%",
    },
  },
  mobileCollapse: {
    background: theme.palette.primary.main,
    padding: "10px 25px",
    boxShadow: "unset",
    width: "55%",
    display: "flex",
    flexDirection: "column",
  },
  subMenu: {
    "&.MuiButtonBase-root": {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      padding: 0,
    },
  },
  career: {
    fontWeight: 600,
    fontSize: 16,
    fontStyle: "normal",
    textDecoration: "unset",
    margin: 0,
    color: "white",
    cursor: "pointer",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    [theme.breakpoints.down(1350)]: {
      fontSize: 10,
      lineHeight: "44px",
      paddingLeft: 15,
    },
    [theme.breakpoints.down(1024)]: {
      fontSize: 20,
      lineHeight: "44px",
      paddingLeft: 15,
    },
  },
}));

export default function Lists({ toggleDrawer, menuItems }: any) {
  const USER_AGENT = "ufar-united-app";
  const classes = useStyles();
  const sessionState = useSelector(
    //@ts-ignore
    (state) => state.auth.session
  );

  const authState = { isAuthenticated: sessionState.signedIn };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(1024));

  const [openSubmenu, setOpenSubmenu] = useState(
    Object.fromEntries(menuItems?.map(({ _, idx }: any) => [idx, false]))
  );

  const handleOpenSubmenu = (idx: number) => {
    setOpenSubmenu((prev) => ({ ...prev, [idx]: !prev[idx] }));
  };

  return (
    <List
      sx={{
        width: "65%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "baseline",
      }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      {!isMobile
        ? menuItems?.map((pages: any, idx: number) =>
            pages.children ? (
              <div key={idx} className={classes.listItems}>
                {pages.pageID !== 22
                  ? pages.isActive !== 0 && (
                      <ListItemLink
                        primary={pages.page}
                        icon={pages?.icon}
                        to={pages.url.slice(5)}
                        disable={pages?.disable}
                        privateRoute={!!!pages.show}
                        isAuthenticated={authState.isAuthenticated}
                      />
                    )
                  : USER_AGENT !== window.navigator.userAgent && (
                      <a
                        href={
                          /* 
                      authState.isAuthenticated
                        ? `https://ufar-career.smartsoft.am?token=${sessionState.token}`
                        : */ "https://ufar-career.smartsoft.am"
                        }
                        target="_blank"
                        className={classes.career}
                        rel="noreferrer"
                      >
                        {pages.page}
                      </a>
                    )}
                <Collapse
                  in={true}
                  timeout="auto"
                  unmountOnExit
                  className={`${classes.colapse} colapse`}
                >
                  <List component="div" disablePadding>
                    {pages?.children?.map((page: any, ind: number) =>
                      page.children.length ? (
                        <div key={`${idx}id${ind}`}>
                          {page.icon && (
                            <ListItemIcon>{page?.icon}</ListItemIcon>
                          )}
                          <ListSubheader
                            sx={{ pl: 12 }}
                            style={{ fontSize: 16, fontWeight: 600 }}
                          >
                            {page.name}
                          </ListSubheader>
                          {page?.children?.map(
                            (item: any, index: number) =>
                              item.isActive !== 0 && (
                                <ListItemButton
                                  sx={{ pl: 12 }}
                                  key={`${idx}index ${ind} ${index}`}
                                  disableRipple={item?.disable}
                                >
                                  <ListItemLink
                                    primary={item.page}
                                    icon={item?.icon}
                                    to={item.url}
                                    colapse
                                    privateRoute={!!item.show}
                                    isAuthenticated={authState.isAuthenticated}
                                  />
                                </ListItemButton>
                              )
                          )}
                        </div>
                      ) : (
                        page.isActive !== 0 && (
                          <ListItemLink
                            key={`${idx}id${ind}`}
                            primary={page.page}
                            to={page.url.slice(5)}
                            colapse
                            privateRoute={!!!page.show}
                            isAuthenticated={authState.isAuthenticated}
                          />
                        )
                      )
                    )}
                  </List>
                </Collapse>
              </div>
            ) : (
              pages.isActive !== 0 && (
                <ListItemLink
                  key={`${idx}`}
                  primary={pages.page}
                  icon={pages?.icon}
                  to={pages.url}
                  privateRoute={!!!pages.show}
                  isAuthenticated={authState.isAuthenticated}
                />
              )
            )
          )
        : menuItems?.map((pages: any, idx: number) =>
            pages.children ? (
              <>
                <div key={idx} className={classes.listItems}>
                  {pages.pageID !== 22
                    ? pages.isActive !== 0 && (
                        <ListItemButton style={{ padding: 0 }}>
                          <ListItemLink
                            primary={pages.page}
                            icon={pages?.icon}
                            to={pages.url.slice(5)}
                            disable={pages.disable}
                            privateRoute={!!!pages.show}
                            isAuthenticated={authState.isAuthenticated}
                            toggleDrawer
                          />
                          {pages.pageID !== 6 &&
                            (openSubmenu[idx] ? (
                              <ExpandLess
                                onClick={() => handleOpenSubmenu(idx)}
                              />
                            ) : (
                              <ExpandMore
                                onClick={() => handleOpenSubmenu(idx)}
                              />
                            ))}
                        </ListItemButton>
                      )
                    : USER_AGENT !== window.navigator.userAgent && (
                        <a
                          href={
                            /* authState.isAuthenticated
                          ? `https://ufar-career.smartsoft.am?token=${sessionState.token}`
                          :  */ "https://ufar-career.smartsoft.am"
                          }
                          target="_blank"
                          className={classes.career}
                          rel="noreferrer"
                        >
                          {pages.page}
                        </a>
                      )}
                  <Collapse in={openSubmenu[idx]} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {pages?.children?.map((page: any, ind: number) =>
                        page?.children?.length ? (
                          <div key={`${idx}id${ind}`}>
                            {page?.children?.map(
                              (item: any, index: number) =>
                                item.isActive !== 0 && (
                                  <ListItemButton
                                    sx={{ pl: 12 }}
                                    key={`${idx}index ${ind} ${index}`}
                                    disableRipple={item?.disable}
                                  >
                                    <ListItemLink
                                      primary={item.page}
                                      icon={item?.icon}
                                      to={item.url}
                                      disable={item.disable}
                                      privateRoute={!!item.show}
                                      isAuthenticated={
                                        authState.isAuthenticated
                                      }
                                      toggleDrawer
                                      colapse
                                    />
                                  </ListItemButton>
                                )
                            )}
                          </div>
                        ) : (
                          page.isActive !== 0 && (
                            <ListItemLink
                              key={`${idx}id${ind}`}
                              primary={page.page}
                              icon={page?.icon}
                              to={page.url.slice(5)}
                              disable={page.disable}
                              privateRoute={!!page.show}
                              isAuthenticated={authState.isAuthenticated}
                              toggleDrawer
                              colapse
                            />
                          )
                        )
                      )}
                    </List>
                  </Collapse>
                </div>
              </>
            ) : (
              <ListItemLink
                key={`${idx}`}
                primary={pages.page}
                icon={pages?.icon}
                to={pages.url.slice(5)}
                disable={pages.disable}
                privateRoute={!!pages.show}
                isAuthenticated={authState.isAuthenticated}
                toggleDrawer
              />
            )
          )}{" "}
    </List>
  );
}
