import BaseService from "./BaseService";

interface FetchDataResponse {
  data: any;
}

interface FetchDataParams {
  [key: string]: any;
}

const ApiService = {
  fetchData(param: FetchDataParams): Promise<FetchDataResponse> {
    return new Promise((resolve, reject) => {
      BaseService(param)
        .then((response) => {
          resolve(response);
        })
        .catch((errors) => {
          reject(errors);
        });
    });
  },
};

export default ApiService;
