import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "./store";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import App from "./App";
import "./index.css";

import "react-toastify/dist/ReactToastify.css";

const getDesignTokens = (mode: string) => {
  switch (mode) {
    case "light":
      return {
        palette: {
          mode: "light",
          primary: {
            main: "#444",
          },
          secondary: {
            main: "#5169F3",
          },
          error: {
            main: "#FF3333",
          },
          divider: "#24355a",
          text: {
            primary: "#24355a",
            secondary: "#24355a",
          },
        },
      };
    case "dark":
      return {
        palette: {
          mode: "dark",
          primary: {
            main: "#002494",
          },
          secondary: {
            main: "#5169F3",
          },
          error: {
            main: "#FF3333",
          },
          text: {
            primary: "#bbb",
            secondary: "#bbb",
          },
        },
      };
  }
};

const theme = createTheme({
  ...getDesignTokens("dark"),
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <App />
          <ToastContainer autoClose={3000} />
        </ThemeProvider>
      </BrowserRouter>
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
