import React from "react";
interface RoutesProps {
  path: string;
  name: string;
  privateRoute?: boolean;
  navbar?: boolean;
  disable?: boolean;
  exact?: boolean;
  component?: React.ElementType;
  subMenu?: RoutesProps[];
  icon?: JSX.Element;
}

export const routes: RoutesProps[] = [
  {
    path: "/main",
    name: "Գլխավոր",
    component: React.lazy(() => import("./pages/publicPages/Home")),
    navbar: true,
    subMenu: [
      {
        path: "/main/about",
        name: "Մեր մասին",
        component: React.lazy(() => import("./pages/publicPages/Home/AboutUs")),
      },
      {
        path: "/main/map",
        name: "Աշխարհի քարտեզ",
        component: React.lazy(() => import("./pages/publicPages/Home/Map")),
      },
      {
        path: "/main/services",
        name: "Ծառայություններ",
        component: React.lazy(
          () => import("./pages/publicPages/Home/Services")
        ),
      },
      {
        path: "/main/contact",
        name: "Կոնտակտներ",
        component: React.lazy(
          () => import("./pages/publicPages/Home/ContactUs/ContactUs")
        ),
      },
    ],
  },
  {
    path: "/main/services/service/:id",
    name: "Service",
    component: React.lazy(
      () => import("./pages/publicPages/Home/Services/SingleService")
    ),
    navbar: false,
  },
  {
    path: "/directory",
    name: "Տեղեկատու",
    navbar: true,
    privateRoute: true,
    component: React.lazy(
      () => import("./pages/privatePages/InformationCenter")
    ),
  },
  {
    path: "/events",
    name: "Միջոցառումներ",
    exact: true,
    privateRoute: true,
    navbar: true,
    component: React.lazy(
      () =>
        import(
          "./pages/privatePages/CalendarAndEvents/Events/EventsAndCalendar"
        )
    ),

    subMenu: [
      {
        path: "/events/upcoming",
        name: "Գալիք միջոցառումներ",
        privateRoute: true,
        component: React.lazy(
          () =>
            import("./pages/privatePages/CalendarAndEvents/Events/SingleEvent")
        ),
      },
      {
        path: "/events/passed",
        name: "Անցյալ միջոցառումներ",
        component: React.lazy(
          () =>
            import(
              "./pages/privatePages/CalendarAndEvents/PastEvents/SinglePastEvent"
            )
        ),
        privateRoute: true,
      },
      {
        path: "/events/gallery",
        name: "Լուսանկարների պատկերասրահ",
        component: React.lazy(
          () => import("./pages/privatePages/CalendarAndEvents/Gallery")
        ),
        privateRoute: true,
      },
      {
        path: "/events/videoGallery",
        name: "Տեսադարան",
        component: React.lazy(
          () => import("./pages/privatePages/CalendarAndEvents/Video")
        ),
        privateRoute: true,
      },
    ],
  },
  {
    path: "/events/main",
    name: "",
    exact: true,
    privateRoute: true,
    navbar: false,
    component: React.lazy(
      () => import("./pages/privatePages/CalendarAndEvents/Events/SingleEvent")
    ),
  },
  {
    path: "/events/singleEvent/:id",
    name: "",
    privateRoute: true,
    exact: true,
    navbar: false,
    component: React.lazy(
      () => import("./pages/privatePages/CalendarAndEvents/Events/ReadEvent")
    ),
  },
  {
    path: "/news",
    name: "Նորություններ",
    exact: true,
    navbar: true,
    component: React.lazy(() => import("./pages/publicPages/News")),
    subMenu: [
      {
        path: "/news/main",
        name: "Նորություններ",
        component: React.lazy(
          () => import("pages/publicPages/News/News/index")
        ),
      },
      {
        path: "/news/successStory",
        name: "Դիմանկար",
        component: React.lazy(
          () => import("./pages/publicPages/News/SuccessStory/SuccessStory")
        ),
      },
      {
        path: "/news/journal",
        name: "Ամսագրեր",
        component: React.lazy(() => import("./pages/publicPages/News/Journal")),
      },
    ],
  },
  {
    path: "/news/main/single/:id",
    name: "News Single",
    component: React.lazy(() => import("pages/publicPages/News/News/Single")),
    navbar: false,
  },
  {
    path: "/news/successStory/single/:id",
    name: "Success stories",
    component: React.lazy(
      () => import("./pages/publicPages/News/SuccessStory/Single")
    ),
    navbar: false,
  },

  {
    path: "/network/category/:categoryID/:groupID",
    name: "",
    navbar: false,
    privateRoute: true,
    component: React.lazy(
      () => import("pages/privatePages/Networks/SingleGroup")
    ),
  },
  {
    path: "/network/category/post/:forumID/:groupID/",
    name: "",
    navbar: false,
    privateRoute: true,
    component: React.lazy(
      () =>
        import("pages/privatePages/Networks/SingleGroup/Tabs/Forum/SinglePost")
    ),
  },
  {
    path: "/network/category/event/:eventID/:groupID/",
    name: "",
    navbar: false,
    privateRoute: true,
    component: React.lazy(
      () =>
        import(
          "pages/privatePages/Networks/SingleGroup/Tabs/Events/SingleEvent"
        )
    ),
  },
  {
    path: "/network/category/articles/:articleID/:groupID",
    name: "",
    navbar: false,
    privateRoute: true,
    component: React.lazy(
      () =>
        import(
          "pages/privatePages/Networks/SingleGroup/Tabs/Articles/SingleArticle"
        )
    ),
  },
  {
    path: "/network/category/:categoryID/:groupID/event/single",
    name: "",
    navbar: false,
    privateRoute: true,
    component: React.lazy(
      () =>
        import(
          "pages/privatePages/Networks/SingleGroup/Tabs/Events/SingleEvent"
        )
    ),
  },
  {
    path: "/network",
    name: "Ցանցեր",
    privateRoute: true,
    exact: true,
    navbar: true,
    component: React.lazy(() => import("pages/privatePages/Networks")),
    subMenu: [
      {
        path: "/network/category/:id",
        name: "Ակումբներ ըստ երկրների",
        component: React.lazy(
          () => import("./pages/privatePages/Networks/SingleNetwork")
        ),
        privateRoute: true,
      },
      {
        path: "/network/category/:id",
        name: "Ակումբներ ըստ մասնագիտությունների",
        component: React.lazy(
          () => import("./pages/privatePages/Networks/SingleNetwork")
        ),
        privateRoute: true,
      },
      {
        path: "/network/category/:id",
        name: "Ակումբներ ըստ ավարտելու տարեթվի",
        component: React.lazy(
          () => import("./pages/privatePages/Networks/SingleNetwork")
        ),
        privateRoute: true,
      },
      {
        path: "/network/category/:id",
        name: "Ակումբներ ըստ հետաքրքրությունների",
        component: React.lazy(
          () => import("./pages/privatePages/Networks/SingleNetwork")
        ),
        privateRoute: true,
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: React.lazy(() => import("./pages/publicPages/LogIn/LogIn")),
    exact: true,
  },
  {
    path: "/forgot-password",
    name: "Forgot Password",
    component: React.lazy(
      () => import("./pages/publicPages/LogIn/ForgotPassword")
    ),
    exact: true,
  },
  {
    path: "/profile",
    name: "Profile",
    component: React.lazy(
      () => import("./pages/privatePages/Profile/EditProfile")
    ),
    exact: true,
    privateRoute: true,
  },
  {
    path: "/profile/activity",
    name: "My Activity",
    component: React.lazy(
      () => import("./pages/privatePages/Profile/Activity")
    ),
    exact: true,
    privateRoute: true,
  },
  {
    path: "/pdfViews",
    name: "",
    exact: true,
    navbar: false,
    component: React.lazy(() => import("./pages/publicPages/Home/PDFViews")),
  },
];
