import { createSlice } from "@reduxjs/toolkit";

export const networkSlice = createSlice({
  name: "networks",
  initialState: {
    requestsCount: 0,
  },
  reducers: {
    setRequestsCount: (state, action) => {
      state.requestsCount = action.payload + state.requestsCount;
    },
  },
});

export const { setRequestsCount } = networkSlice.actions;

export default networkSlice.reducer;
