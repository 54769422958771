import axios from "axios";
import deepParseJson from "utils/deepParseJson";
// import {fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import { getI18n } from "react-i18next";
import store from "../store";
import { onSignOutSuccess } from "../store/auth/sessionSlice";

const PERSIST_STORE_NAME = "user";
export const TOKEN_TYPE = "Bearer ";
const REQUEST_HEADER_AUTH_KEY = "Authorization";
export const NODE_ENV: string | "development" | "production" = "production";
// export const API_PREFIX = "http://192.168.188.159:5002/"; //Suren
// export const API_PREFIX = "http://192.168.188.86:3002/"; //Pargev
// export const API_PREFIX = "http://192.168.188.70:5000/"; // Vahag
export const API_PREFIX = "https://back-ufar-client-alumni.smartsoft.am/";

export const appConfig = {
  apiPrefix: API_PREFIX,
  authenticatedEntryPath: "/main",
  unAuthenticatedEntryPath: "/main",
  tourPath: "/",
  enableMock: false,
};

axios.defaults.withCredentials = true;

const unauthorizedCode = [401];

const BaseService = axios.create({
  timeout: 60000,
  baseURL: appConfig.apiPrefix,
  withCredentials: true,
});

BaseService.interceptors.request.use(
  (config) => {
    if (NODE_ENV === "development") {
      const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME);
      const persistData = deepParseJson(rawPersistData);

      const accessToken = persistData.auth.session.token;

      if (accessToken && config.headers) {
        config.headers[REQUEST_HEADER_AUTH_KEY] = `${TOKEN_TYPE}${accessToken}`;
      }
    }
    config.headers["Accept-Language"] =
      config.headers["Accept-Language"] || getI18n().language;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

BaseService.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response } = error;

    if (response && unauthorizedCode.includes(response.status)) {
      store.dispatch(onSignOutSuccess());
    }

    return Promise.reject(error);
  }
);

export default BaseService;
