import { combineReducers, Action } from "redux";
import theme from "./theme/themeSlice";
import auth from "./auth";
import settings from "./settings";
import networks from "./networks";

type AsyncReducers = { [key: string]: (state: any, action: Action) => any };

const rootReducer =
  (asyncReducers: AsyncReducers = {}) =>
  (state: any, action: Action) => {
    const combinedReducer = combineReducers({
      theme,
      auth,
      settings,
      networks,
      ...asyncReducers,
    });
    return combinedReducer(state, action);
  };

export default rootReducer;
