import React, { useState } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import ListItemLink from "../ListItemLink";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";

export interface MenuItemProps {
  title?: string;
  icon?: any;
  onClick?: any;
  path?: string;
  disable?: boolean;
  imgPath?: string;
  key?: string;
}

interface FadeMenuProps {
  title?: string;
  icon?: any;
  menuItems: MenuItemProps[];
  imgPath?: string | any;
}

const useStyles = makeStyles((theme) => ({
  langBlock: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down(1350)]: {
      justifyContent: "flex-start",
      paddingLeft: 10,
    },
  },
  menu: {
    display: "flex",
  },
  rotate: {
    transform: "rotate(180deg)",
    transition: ".4s!important",
  },
  rotateIcon: {
    color: "#fff",
    transition: ".4s!important",
  },
}));

export default function FadeMenu({
  title,
  icon,
  menuItems,
  imgPath,
}: FadeMenuProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const classes = useStyles();
  let open = Boolean(anchorEl);
  const { i18n } = useTranslation();
  const [imagePath, setImagePath] = useState(imgPath);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.langBlock}>
      <Button
        id="fade-button"
        aria-controls={open ? "fade-menu" : undefined}
        aria-haspopup="true"
        style={{
          color: "white",
        }}
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {icon ? (
          icon
        ) : imgPath ? (
          <img
            src={imagePath}
            alt="Language"
            width="35px"
            height="18px"
            style={{
              boxShadow: "-2px -2px 8px #888888",
            }}
          />
        ) : (
          title
        )}
        {imgPath && (
          <ExpandMoreIcon
            className={`${open && classes.rotate} ${classes.rotateIcon}`}
          />
        )}
      </Button>
      <Menu
        disableScrollLock
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        className={classes.menu}
      >
        {menuItems.map((item: MenuItemProps, idx: number) => {
          return (
            <MenuItem
              key={idx}
              onClick={() => {
                handleClose();
                if (item.key) {
                  localStorage.setItem("language", item.key);
                  i18n.changeLanguage(item.key);
                }
                setImagePath(item.imgPath);
              }}
            >
              {item.path && (
                <ListItemLink
                  icon={item.icon}
                  primary={item.title ?? ""}
                  disable={item?.disable}
                  to={item.path}
                />
              )}
              {item.imgPath && (
                <img src={item.imgPath} alt="" width="35px" height="18px" />
              )}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
}
