import { makeStyles } from "@material-ui/core";
import {
  Suspense,
  useEffect,
  useState,
  Fragment,
  useLayoutEffect,
} from "react";
import Footer from "components/Footer";
import NavBar from "components/NavBar";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { routes } from "router";
import Loading from "components/Loading";
import NoMatch from "pages/publicPages/NoMatch";
import { PrivateRoute } from "components/PrivateRoute";
import LoginDialog from "components/LoginDialog";
import { useDispatch, useSelector } from "react-redux";
import { apiGetMenu } from "services/MenuService";
import { useTranslation } from "react-i18next";
import "moment/locale/en-gb";
import "moment/locale/hy-am";
import "moment/locale/fr";
import moment from "moment";
import { apiGetGeneral } from "services/GeneralService";
import { API_PREFIX, NODE_ENV } from "services/BaseService";
import { FooterProps } from "components/Footer/Footer";
import { apiGetFooterData } from "services/SettingServices";
import Notification from "components/Notification";
import { SignInResult } from "utils/hooks/useAuth";
import { onSignInSuccess } from "store/auth/sessionSlice";
import { apiGetUser } from "services/UserService";
import { setUser } from "store/auth/userSlice";
import deepParseJson from "utils/deepParseJson";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "auto",
    boxSizing: "border-box",
    overflowX: "hidden",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  main:{
    flexGrow: 1,
    display: "flex",
   
    flexDirection: "column",
    [theme.breakpoints.down(1200)]: {
      marginTop:100,
    },
  }
}));

function ScrollToTop({ children }: any) {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return children;
}

const App = () => {
  const { i18n } = useTranslation();
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const lang = localStorage.getItem("language");
  const [menuItems, setMenuItems] = useState([]);
  const [logo, setLogo] = useState("");
  const [footerData, setFooterData] = useState<Partial<FooterProps>>({});
  const [openCookie, setOpenCookie] = useState(true);
  const dispatch = useDispatch();
  const USER_AGENT = "ufar-united-app";
  useEffect(() => {
    // Check localStorage to see if the notification has been closed before
    const isCookieNotificationClosed = localStorage.getItem(
      "cookieNotificationClosed"
    );

    if (isCookieNotificationClosed) {
      setOpenCookie(false); // If notification was closed before, don't show it
    }
  }, []);

  const handleCloseNotification = () => {
    setOpenCookie(false);
    // Store in localStorage that the notification has been closed
    localStorage.setItem("cookieNotificationClosed", "true");
  };

  const sessionState = useSelector(
    //@ts-ignore
    (state) => state.auth.session
  );

  const authState = { isAuthenticated: sessionState.signedIn };

  moment.locale(lang === "hy" ? "hy-am" : lang === "en" ? "en-gb" : "fr");

  const setToken = async (token: string, user: any): Promise<SignInResult> => {
    try {
      if (token) {
        dispatch(onSignInSuccess(token));
        if (user) {
          dispatch(setUser(user));
        }

        return {
          status: null,
          message: null,
        };
      } else {
        throw new Error("Data is not available in response");
      }
    } catch (error: any) {
      return {
        status: "failed",
        message: error.response?.data?.message || error.message,
      };
    }
  };

  // useEffect(() => {
  //   // if(!authState.isAuthenticated){
  //   const rawPersistData = localStorage.getItem("user");
  //   const persistData = deepParseJson(rawPersistData);

  //   let accessToken: string = "";
  //   if (NODE_ENV === "development") {
  //     accessToken = persistData.auth.session.token;
  //   }
  //   apiGetUser(accessToken).then((res) => {
  //     if (res.data?.data?.token) setToken(res.data.data.token, res.data.data);
  //   });
  //   // }
  // }, []);

  useEffect(() => {
    const rawPersistData = localStorage.getItem("user");
    const persistData = deepParseJson(rawPersistData);

    let accessToken: string = "";
    if (NODE_ENV === "development") {
      accessToken = persistData.auth.session.token;
    }
    apiGetUser(accessToken).then((res) => {
      if (res.data?.data?.token) setToken(res.data.data.token, res.data.data);
    });
    if (location.pathname === "/") navigate("/main");
  }, [location.pathname]);

  useEffect(() => {
    apiGetMenu().then((res) => {
      setMenuItems(res.data.data);
    });
    apiGetGeneral().then((res) => {
      setLogo(res?.data?.data?.img);
      document.title = res?.data?.data?.web_title;
      let link: any = document.querySelector("link[rel~='icon']");
      if (link) {
        link.href = API_PREFIX + res?.data?.data?.favicon;
        document.getElementsByTagName("head")[0].appendChild(link);
      }
      const metaTagDescription = document.querySelector(
        'meta[name="description"]'
      );
      const webDescription = res?.data?.data?.web_description;
      if (metaTagDescription && webDescription) {
        metaTagDescription.setAttribute("content", webDescription);
      }

      const metaTagKeywords = document.querySelector(
        'meta[name="description"]'
      );
      const webKeywords = res?.data?.data?.web_keywords;
      if (metaTagKeywords && webKeywords) {
        metaTagKeywords.setAttribute("content", webKeywords);
      }
    });
  }, [i18n.language]);

  useEffect(() => {
    apiGetFooterData().then((res) => {
      setFooterData(res.data.data);
    });
  }, [i18n.language]);

  return (
    <div className={classes.container}>
      {authState.isAuthenticated !== undefined ? (
        <>
          <NavBar menu={menuItems} logo={API_PREFIX + logo} />
          <main
          className={classes.main}
          >
            <Suspense fallback={<Loading />}>
              <ScrollToTop>
                <Routes>
                  {routes.map((route, id) => {
                    return (
                      <Fragment key={id}>
                        {route.component && (
                          <Route
                            key={route.path}
                            path={route.path}
                            element={
                              route.privateRoute ? (
                                <PrivateRoute
                                  isAuthenticated={authState.isAuthenticated}
                                >
                                  {<route.component />}
                                </PrivateRoute>
                              ) : (
                                route.component && <route.component />
                              )
                            }
                          />
                        )}
                        {route.subMenu?.map((route) => (
                          <Route
                            key={route.path}
                            path={route.path}
                            element={
                              route.privateRoute ? (
                                <PrivateRoute
                                  isAuthenticated={authState.isAuthenticated}
                                >
                                  {route.component && <route.component />}
                                </PrivateRoute>
                              ) : (
                                route.component && <route.component />
                              )
                            }
                          />
                        ))}
                      </Fragment>
                    );
                  })}
                  <Route path="*" element={<NoMatch />} />
                </Routes>
              </ScrollToTop>
            </Suspense>
          </main>

          {location.pathname !== "/login" &&  USER_AGENT !== window.navigator.userAgent &&  (

            <Footer menuItems={menuItems} footerData={footerData} />
          )}
          <LoginDialog />
        </>
      ) : (
        <Loading />
      )}

      <Notification open={openCookie} onClose={handleCloseNotification} />
    </div>
  );
};

export default App;
