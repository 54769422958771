const isNumString = (str: string): boolean => !isNaN(Number(str));

function deepParseJson(jsonString: any): any {
  if (!jsonString) {
    return {};
  }
  if (typeof jsonString === "string") {
    if (isNumString(jsonString)) {
      return jsonString;
    }
    try {
      return deepParseJson(JSON.parse(jsonString));
    } catch (err) {
      return jsonString;
    }
  } else if (Array.isArray(jsonString)) {
    return jsonString.map((val) => deepParseJson(val));
  } else if (typeof jsonString === "object" && jsonString !== null) {
    return Object.keys(jsonString).reduce(
      (obj: Record<string, unknown>, key) => {
        const val = jsonString[key];
        obj[key] = isNumString(val) ? val : deepParseJson(val);
        return obj;
      },
      {}
    );
  } else {
    return jsonString;
  }
}

export default deepParseJson;
