import React from "react";
import { Snackbar, makeStyles } from "@material-ui/core";
import { Alert } from "@mui/material";
import { t } from "i18next";

const useStyles = makeStyles((theme) => ({
  snackbar: {
    boxShadow: "1px 2px 2px 2px rgba(0, 0, 0, 0.25)",
    borderRadius: 4,
    "&.MuiSnackbar-root": {
      width: "80%",
      [theme.breakpoints.down("xs")]: {
        width: "unset",
      },
    },
    "& .MuiPaper-root": {
      width: "100%",
      fontSize: 14,
      backgroundColor: "#fff",
    },
  },
}));

const Notification = ({ open, onClose }: { open: boolean; onClose: any }) => {
  const classes = useStyles();
  const handleClose = () => {
    onClose();
  };

  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      className={classes.snackbar}
    >
      <Alert icon={false} onClose={handleClose} severity="info">
        This website collects cookies to deliver a better user experience{" "}
        {t("cookie")}
      </Alert>
    </Snackbar>
  );
};

export default Notification;
