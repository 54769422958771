import ApiService from "./ApiService";

interface LoginParams {
  email: string;
  password: string;
}

export async function apiSignIn(data: LoginParams) {
  return ApiService.fetchData({
    url: "/auth/login",
    method: "post",
    data,
    headers: {
      "Client-Identifier": "ufar-alumni",
    },
  });
}

export async function apiSignOut() {
  return ApiService.fetchData({
    url: "/auth/logout",
    method: "post",
    headers: {
      "Client-Identifier": "ufar-alumni",
    },
  });
}
