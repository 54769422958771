import { useSelector, useDispatch } from "react-redux";
import { setUser, initialState, IUserData } from "store/auth/userSlice";
import { onSignInSuccess, onSignOutSuccess } from "store/auth/sessionSlice";
import { useNavigate } from "react-router-dom";
import useQuery from "./useQuery";
import { injectReducer } from "store";
import reducer from "store/auth";
import { appConfig } from "services/BaseService";
import { apiSignIn, apiSignOut } from "services/AuthService";
import { apiGetUser } from "services/UserService";

interface Session {
  token: string | null;
  signedIn: boolean;
}

export interface SignInResult {
  status: string | null;
  message: string | null;
}

interface AuthHook {
  userType: number | null;
  authenticated: boolean | null | string;
  signIn: (values: any) => Promise<SignInResult>;
  signOut: () => Promise<void>;
}

injectReducer("auth", reducer);

const useAuth = (): AuthHook => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const query = useQuery();

  const session: Session = useSelector((state: any) => state.auth.session);
  const userData: IUserData = useSelector((state: any) => state.auth.user);

  const signIn = async (values: any): Promise<SignInResult> => {
    try {
      const resp = await apiSignIn(values);
      if (resp.data.data) {
        const { token } = resp.data.data;
        if (token) {
          dispatch(onSignInSuccess(token));
        }
        const userRes = await apiGetUser(token);
        if (userRes.data.data) {
          dispatch(setUser(userRes.data.data));
        }

        const redirectUrl = query.get("redirectUrl");
        navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);
        return {
          status: null,
          message: null,
        };
      } else {
        throw new Error("Data is not available in response");
      }
    } catch (error: any) {
      return {
        status: "failed",
        message: error.response?.data?.message || error.message,
      };
    }
  };

  const handleSignOut = (): void => {
    dispatch(onSignOutSuccess());
    dispatch(setUser(initialState));
    navigate(appConfig.unAuthenticatedEntryPath);
  };

  const signOut = async (): Promise<void> => {
    const currentLang = localStorage.getItem("language");
    await apiSignOut().then(() => {
      handleSignOut();
      localStorage.clear();
      currentLang && localStorage.setItem("language", currentLang);
    });
  };

  return {
    authenticated: session.token && session.signedIn,
    userType: userData.userTypeID,
    signIn,
    signOut,
  };
};

export default useAuth;
