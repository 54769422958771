import { createSlice } from "@reduxjs/toolkit";

export interface IUserData {
  id: number | null;
  name: string | null;
  lastName: string | null;
  phone: number | null;
  email: string | null;
  country: string | null;
  city: string | null;
  address: string | null;
  img: string | Blob | null;
  university: string | null;
  yearsOfStudyStart: number | null;
  yearsOfStudyEnd: number | null;
  faculty: string | null;
  userTypeID: number | null;
  industry: string | null;
  numberOfEmployeesID: number | null;
  directorName: string | null;
  contactPersonName: string | null;
  contactPersonPhone: number | null;
  description_hy: string | null;
  description_fr: string | null;
  description_en: string | null;
  url: string | null;
}

export const initialState: IUserData = {
  id: null,
  name: null,
  lastName: null,
  phone: null,
  email: null,
  country: null,
  city: null,
  address: null,
  img: "",
  university: null,
  yearsOfStudyStart: null,
  yearsOfStudyEnd: null,
  faculty: null,
  userTypeID: null,
  industry: null,
  numberOfEmployeesID: null,
  directorName: null,
  contactPersonName: null,
  contactPersonPhone: null,
  description_hy: null,
  description_fr: null,
  description_en: null,
  url: null,
};

export const userSlice = createSlice({
  name: "auth/user",
  initialState,
  reducers: {
    setUser: (_, action) => {
      return action.payload;
    },
    userLoggedOut: () => initialState,
  },
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;
