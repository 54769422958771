import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  makeStyles,
} from "@material-ui/core";
import close from "assets/icons/closeIcon.png";
import { t } from "i18next";

const useStyles = makeStyles((theme) => ({
  dialogContainer: {
    "& .MuiPaper-rounded": {
      background: "#FFFFFF",
      borderRadius: 16,
    },
  },
  titleBlock: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontWeight: 700,
    fontSize: 24,
    lineHeight: "29px",
    color: "#000000",
    paddingBottom: 8,
    [theme.breakpoints.down("xs")]: {
      fontSize: 22,
    },
  },
  title: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  form: {
    margin: "50px auto",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    color: "#000000",
    fontWeight: 500,
    fontSize: 16,
    lineHeight: "24px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      margin: "10px auto",
    },
  },
  cancelBtn: {
    background: "#868686",
    borderRadius: 32,
    color: "#fff",
    fontSize: 18,
    height: 46,
    width: "38%",
    lineHeight: "22px",
    "&.MuiButton-root": {
      textTransform: "unset",
      cursor: "pointer",
    },
    "&:hover": {
      background: "#868686",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 15,
      width: "44%",
    },
  },
  confirmBtn: {
    background: theme.palette.primary.main,
    borderRadius: 32,
    color: "#fff",
    fontSize: 18,
    height: 46,
    width: "38%",
    lineHeight: "22px",
    "&.MuiButton-root": {
      textTransform: "unset",
      cursor: "pointer",
    },
    "&:hover": {
      background: theme.palette.primary.main,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 15,
      width: "44%",
    },
  },
}));

const LogoutDialog = ({ open, setOpen, logout }: any) => {
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className={classes.dialogContainer}
      style={{ zIndex: 1200 }}
      fullWidth
    >
      <DialogContent>
        <div className={classes.titleBlock}>
          <div className={classes.title}>{t("log_out")}</div>
          <img
            src={close}
            alt="closeIcon"
            onClick={handleClose}
            style={{ cursor: "pointer" }}
          />
        </div>
        <Divider />
        <div className={classes.form}>{t("confirm_log_out")}</div>

        <DialogActions style={{ justifyContent: "center", marginBottom: 40 }}>
          <Button
            onClick={handleClose}
            variant="outlined"
            className={classes.cancelBtn}
          >
            {t("cancel")}
          </Button>
          <Button
            onClick={() => {
              setOpen(false);
              logout();
            }}
            variant="outlined"
            className={classes.confirmBtn}
          >
            {t("log_out")}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default LogoutDialog;
