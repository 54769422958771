import { useState } from "react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  Divider,
  FormControlLabel,
  makeStyles,
} from "@material-ui/core";
import close from "assets/icons/closeIcon.png";
import hide from "assets/icons/hidePassword.png";
import show from "assets/icons/showPassword.png";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { UserTypes } from "pages/publicPages/LogIn/LogIn";
import { useNavigate } from "react-router-dom";
import { Alert } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import useAuth from "utils/hooks/useAuth";
import { setSignInModal } from "store/settings/settingsSlice";
import { t } from "i18next";

const useStyles = makeStyles((theme) => ({
  dialogContainer: {
    "& .MuiPaper-rounded": {
      background: "#FFFFFF",
      borderRadius: 16,
    },
  },
  titleBlock: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontWeight: 700,
    fontSize: 24,
    lineHeight: "29px",
    color: "#000000",
    paddingBottom: 20,
  },
  title: {
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
    width: "100%",
  },
  form: {
    margin: "50px auto",
    display: "flex",
    flexDirection: "column",
    width: "80%",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  inputTitle: {
    fontWeight: 400,
    fontSize: 18,
    lineHeight: "22px",
    color: "#666666",
  },
  passwordInputBlock: {
    display: "flex",
    justifyContent: "space-between",
  },
  hideBlock: {
    display: "flex",
    alignItems: "center",
    "&:hover": {
      cursor: "pointer",
    },
  },
  hideText: {
    fontWeight: 400,
    fontSize: 18,
    lineHeight: "22px",
    color: "rgba(102, 102, 102, 0.8)",
  },
  input: {
    "&:-internal-autofill-selected": {
      backgroundColor: " red",
    },
    "&.MuiFormControl-root": {
      marginBottom: 35,
    },
    "& .MuiInputBase-root": {
      borderRadius: 12,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "1px solid rgba(102, 102, 102, 0.35)",
      borderWidth: 1,
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: theme.palette.primary.main,
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgba(234, 33, 33, 0.35)",
    },
    "& .MuiFormHelperText-root.Mui-error": {
      fontSize: 14,
      fontWeight: 400,
      color: "#EA2121",
      marginLeft: 0,
    },
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    width: "100%",
    height: 56,
    background: theme.palette.primary.main,
    borderRadius: 32,
    textTransform: "unset",
    fontWeight: 300,
    fontSize: 18,
    lineHeight: "22px",
    color: "#FFFFFF",
    "&.MuiButton-contained.Mui-disabled": {
      background: "#868686",
      color: "#fff",
    },
  },
  bottomBlock: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontWeight: 400,
    fontSize: 16,
    lineHight: "20px",
    color: "#333333",
    [theme.breakpoints.down(410)]: {
      flexDirection: "column",
    },
  },
  rememberBlock: {
    display: "flex",
    alignItems: "center",
  },
  checkbox: {
    "&.MuiCheckbox-colorSecondary.Mui-checked": {
      color: "#111111",
    },
  },
  help: {
    "&:hover": {
      cursor: "pointer",
    },
  },

  alertContainer: {
    width: "80%",
  },
  errorAlert: {
    marginTop: theme.spacing(3),
  },
}));

const LoginDialog = () => {
  const classes = useStyles();

  const [showPassword, setShowPassword] = useState(false);
  const [user, setUser] = useState<UserTypes>({
    email: "",
    password: "",
    isSubmitting: false,
    errorMessage: null,
    inputErrors: null,
  });

  const signInModal = useSelector((state: any) => state.settings.signInModal);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { signIn } = useAuth();

  const handleClose = () => {
    dispatch(setSignInModal(false));
  };

  const handleSubmit = async () => {
    const { email, password } = user;
    setUser((prev) => ({ ...prev, isSubmitting: true }));

    const result = await signIn({ email, password });

    if (result.status === "failed") {
      setUser((prev) => ({ ...prev, errorMessage: result.message }));
    } else {
      setUser((prev) => ({ ...prev, isSubmitting: false }));
      handleClose();
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUser((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const onNeedHelp = () => {
    handleClose();
    navigate("/main/contact");
  };

  return (
    <Dialog
      open={signInModal} // TODO
      onClose={handleClose}
      fullWidth
      className={classes.dialogContainer}
      style={{ zIndex: 1200 }}
    >
      <DialogContent>
        <div className={classes.titleBlock}>
          <div className={classes.title}>{t("please_log_in")}</div>
          <img
            src={close}
            alt="closeIcon"
            onClick={handleClose}
            style={{ cursor: "pointer" }}
          />
        </div>
        <Divider />
        {user.errorMessage && (
          <div className={classes.alertContainer}>
            <Alert severity="error" className={classes.errorAlert}>
              {user.errorMessage}
            </Alert>
          </div>
        )}
        <ValidatorForm
          onSubmit={handleSubmit}
          onError={(errors) => console.log(errors)}
          className={classes.form}
        >
          <span className={classes.inputTitle}>{t("email_address")}</span>
          <TextValidator
            className={classes.input}
            // error={!!user.inputErrors?.email}
            // helperText={user.inputErrors?.email}
            variant="outlined"
            margin="dense"
            fullWidth
            onChange={handleChange}
            placeholder={t("email_address") as string}
            name="email"
            autoComplete="email"
            validators={["required", "isEmail"]}
            errorMessages={[
              t("errorMessage_requiredFields"),
              t("errorMessage_incorrectFormat"),
            ]}
            value={user.email}
          />
          <div className={classes.passwordInputBlock}>
            <span className={classes.inputTitle}>{t("password")}</span>
            <div
              className={classes.hideBlock}
              onClick={() => setShowPassword(!showPassword)}
            >
              <img src={showPassword ? show : hide} alt="hide" />
              <span className={classes.hideText}>
                {showPassword ? t("hide") : t("show")}
              </span>
            </div>
          </div>
          <TextValidator
            className={classes.input}
            // error={!!user.inputErrors?.password}
            // helperText={user.inputErrors?.password}
            variant="outlined"
            margin="dense"
            fullWidth
            width="medium"
            onChange={handleChange}
            placeholder={t("password") as string}
            name="password"
            autoComplete="current-password"
            type={showPassword ? "text" : "password"}
            validators={["required"]}
            errorMessages={[t("errorMessage_requiredFields")]}
            value={user.password}
          />

          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={!user.email || !user.password}
          >
            {t("log_in")}
          </Button>

          <div className={classes.bottomBlock}>
            <div className={classes.rememberBlock}>
              <FormControlLabel
                value="rememberMe"
                control={<Checkbox className={classes.checkbox} />}
                label={t("remember_me")}
                labelPlacement="end"
              />
            </div>

            <div className={classes.help} onClick={onNeedHelp}>
              {t("need_help")}{" "}
            </div>
          </div>
        </ValidatorForm>
      </DialogContent>
    </Dialog>
  );
};

export default LoginDialog;
