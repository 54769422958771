import { useMemo, forwardRef } from "react";
import { ListItem, ListItemText, makeStyles } from "@material-ui/core";
import { NavLink, NavLinkProps } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setSignInModal } from "store/settings/settingsSlice";

const useStyles = makeStyles((theme) => ({
  listIcon: {
    minWidth: 30,
  },
  menuItem: {
    "& .MuiTypography-root": {
      fontWeight: 600,
      fontSize: 16,
      fontStyle: "normal",
      margin: 0,
      color: "white",
      [theme.breakpoints.down(1350)]: {
        fontSize: 10,
        lineheight: "24px",
      },
      [theme.breakpoints.down(1024)]: {
        fontSize: 20,
        lineheight: "24px",
      },
    },
    "&.active  .MuiTypography-root": {
      color: "#EB2D39",
      fontWeight: 600,
      fontSize: 16,
      fontStyle: "bold",
      [theme.breakpoints.down(1350)]: {
        fontSize: 10,
        lineheight: "24px",
      },
      [theme.breakpoints.down(1024)]: {
        fontSize: 20,
        lineheight: "24px",
      },
    },
    "&.active .MuiListItemIcon-root": {
      color: theme.palette.secondary.main,
    },
  },
  colapse: {
    "& .MuiTypography-root": {
      color: "#000",
      [theme.breakpoints.down(1350)]: {
        color: "#fff",
        fontWeight: 500,
        fontSize: 16,
        lineHeight: "20px",
      },
    },
    "&.MuiListSubheader-root": {
      color: "#000000",
      borderRadius: 4,
    },
  },
  text: {
    "&.MuiTypography-body": {
      fontFamily: "Montserrat !important",
    },
  },
}));

interface ListItemLinkProps {
  icon?: React.ReactElement;
  primary: React.ReactElement | string;
  to?: string;
  disable?: boolean;
  colapse?: boolean;
  privateRoute?: boolean;
  isAuthenticated?: boolean;
  toggleDrawer?: any;
}

const ListItemLink = (props: ListItemLinkProps) => {
  const {
    primary,
    to,
    disable,
    colapse,
    privateRoute,
    isAuthenticated,
    toggleDrawer,
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const openLoginDialog = () => {
    dispatch(setSignInModal(true));
  };

  const renderLink = useMemo(
    () =>
      forwardRef<any, Omit<NavLinkProps, "to">>((itemProps, ref) =>
        to ? (
          <NavLink
            to={to}
            ref={ref}
            {...itemProps}
            onClick={(event) => {
              if (privateRoute && !isAuthenticated) {
                openLoginDialog();
                event.preventDefault();
              }
            }}
            style={{
              opacity: 1,
            }}
          />
        ) : null
      ),
    [to, privateRoute, isAuthenticated]
  );

  return (
    <li style={{ cursor: "pointer" }}>
      <ListItem
        button
        {...(to ? { component: renderLink } : {})}
        className={`${classes.menuItem} ${colapse && classes.colapse}`}
        disabled={disable}
      >
        <ListItemText
          primary={primary}
          className={classes.text}
          onClick={toggleDrawer}
        />
      </ListItem>
    </li>
  );
};

export default ListItemLink;
