import ApiService from "./ApiService";

export async function apiGetDictionary(dictionary: string) {
  return ApiService.fetchData({
    url: `/api/setting/dictionary/${dictionary}`,
    method: "get",
  });
}

export async function apiGetLanguages(lang: any) {
  return ApiService.fetchData({
    url: "/global/lang",
    method: "get",
    headers: {
      "Accept-Language": lang,
    },
  });
}

export async function apiGetFooterData(data?: any) {
  return ApiService.fetchData({
    url: "/api/ui-settings/footer",
    method: "get",
    data,
  });
}

export async function apiGetContactData(data?: any) {
  return ApiService.fetchData({
    url: "/api/ui-settings/contact",
    method: "get",
    data,
  });
}

export async function apiContactRequest(data: any) {
  return ApiService.fetchData({
    url: "/api/main/contact",
    method: "post",
    data,
  });
}

export async function apiGetContactPageTitles() {
  return ApiService.fetchData({
    url: `/api/page/contact-us`,
    method: "get",
  });
}

export async function apiUpdateNewsLettersList(data: { email: string }) {
  return ApiService.fetchData({
    url: `/api/newsletter`,
    method: "post",
    data,
  });
}
