import {
  makeStyles,
  Button,
  useTheme,
  useMediaQuery,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Box,
  Collapse,
} from "@material-ui/core";
import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import FadeMenu from "../FadeMenu";
import hy from "assets/icons/am.png";
import fr from "assets/icons/fr.png";
import en from "assets/icons/us.png";
import Lists from "./Lists";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { ListItemButton } from "@mui/material";
import LogoutDialog from "./LogOutDialog";
import { useSelector } from "react-redux";
import useAuth from "utils/hooks/useAuth";
import { API_PREFIX } from "services/BaseService";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import i18n from "utils/i18n";
import { t } from "i18next";
import profile from "assets/profileAvatar.jpg";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    height: 100,
    maxHeight: 100,
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down(1200)]: {
      overflow: "hidden",
      position: "fixed",
      top: 0,
      width: "100%",
      zIndex: 1000,
    },
  },
  logo: {
    height: 70,
    width: 135,
    [theme.breakpoints.down(480)]: {
      width: 120,
    },
  },
  career: {
    color: "#fff",
    fontSize: 16,
    fontWeight: 600,
  },
  logInBtn: {
    width: 135,
    maxHeight: 42,
    height: 42,
    padding: 0,
    color: theme.palette.primary.main,
    backgroundColor: "#fff",
    textTransform: "unset",
    marginLeft: 9,
    weight: 500,
    fontSize: 18,
    lineHeight: "21.94px",
    borderRadius: 92,
    gap: 10,
    [theme.breakpoints.down(1200)]: {
      width: 75,
      fontSize: 14,
      marginLeft: 0,
    },

    "&.MuiButtonBase-root:hover": {
      color: theme.palette.primary.main,
      backgroundColor: "#fff",
    },
  },
  container: {
    width: "82%",
    maxWidth: 1160,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  menuButton: {
    color: "#fff",
    justifyContent: "end",
  },
  drawer: {
    "&.MuiDrawer-root": {
      top: "unset",
    },
    "& .MuiList-root": {
      flexDirection: "column",
      width: "100%",
    },
    "& .MuiPaper-root": {
      width: "100%",
      background: theme.palette.primary.main,
      height: "calc(100% / 1.5 )",
      top: 100,
      textAlign: "left",
      [theme.breakpoints.down(400)]: {
        height: "calc(100% / 1.2 )",
      },
    },
  },
  mobileLogInBtn: {
    color: "#fff",
    textTransform: "unset",
    fontWeight: 700,
    fontSize: 20,
    lineHeight: "24px",
    display: "flex",
    justifyContent: "left",
    padding: 15,
    paddingTop: 50,
  },
  user: {
    "&:hover .box": {
      display: "block!important",
    },
    [theme.breakpoints.down(1350)]: {
      padding: "0px 15px",
    },
  },
  userName: {
    color: "#fff",
    fontSize: 16,
    lineHeight: "20px",
    fontFamily: "Montserrat",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    position: "relative",
  },
  profileImg: {
    height: 42,
    width: 42,
    borderRadius: "50%",
    marginLeft: 6,
    objectFit: "cover",
  },
  profileImgName: {
    height: 42,
    width: 42,
    borderRadius: "50%",
    background: "#ddd",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#366b9f",
    fontWeight: 900,
  },
  box: {
    width: 170,
    position: "absolute",
    borderRadius: 8,
    boxShadow: "0px 5px 12px rgba(90, 95, 105, 0.2)",
    fontWeight: 500,
    fontSize: 16,
    lineHeight: "20px",
    zIndex: 555,
    display: "none",
    "& .MuiList-root": {
      padding: 0,
    },

    "& .MuiButtonBase-root": {
      padding: "4px 10px",
    },
    "& .MuiListItem-root": {
      padding: 0,
    },
  },
  boxs: {
    width: "52%",
    [theme.breakpoints.down("xs")]: {
      width: "85%",
    },
    [theme.breakpoints.down(400)]: {
      width: "98%",
    },
    "& .MuiTypography-body1": {
      fontSize: "20px !important",
      fontWeight: 600,
    },
    "& .MuiListItemButton-root": {
      paddingLeft: 0,
      paddingTop: 0,
      fontSize: "16px !important",
      fontWeight: 600,
    },
    "& .MuiList-root": {
      "& .MuiTypography-body1": {
        fontSize: "16px !important",
        fontWeight: 500,
      },
    },
    // "& .MuiListItemButton-gutters":{
    //   fontSize: "10px !important",
    // }
  },
}));

const menuItems = [
  { imgPath: hy, key: "hy" },
  { imgPath: fr, key: "fr" },
  { imgPath: en, key: "en" },
];

const UserNameBlock = ({ userData }: any) => {
  const classes = useStyles();
  const { signOut } = useAuth();
  const [openLogoutDialog, setOpenLogoutDialog] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(1024));
  const [openSubmenu, setOpenSubmenu] = useState<{ [key: number]: boolean }>(
    {}
  );

  const handleOpenSubmenu = (idx: number) => {
    setOpenSubmenu((prev) => ({ ...prev, [idx]: !prev[idx] }));
  };

  const logout = () => {
    signOut();
  };

  return (
    <div className={classes.user}>
      <div className={classes.userName}>
        {!isMobile &&
          (!userData?.img ||
          userData?.img === "public/userMedia/userdefaultimage.jpg" ? (
            <div className={classes.profileImgName}>
              {`${
                (userData?.name?.[0] ?? "") + (userData?.lastName?.[0] ?? "")
              }`}
            </div>
          ) : (
            <img
              src={userData?.img ? API_PREFIX + userData?.img : profile}
              alt=""
              className={classes.profileImg}
            />
          ))}
      </div>
      <Box
        className={isMobile ? `${classes.boxs} boxs` : `${classes.box} box`}
        sx={{
          fontSize: "20px !important",
          width: "100%",
          bgcolor: isMobile ? "transparent" : "background.paper",
          color: isMobile ? "#fff" : "#000000",
          zIndex: 1600,
        }}
      >
        {isMobile ? (
          <>
            <ListItemButton onClick={() => handleOpenSubmenu(0)}>
              <ListItemText primary={t("personal_information")} />
              {openSubmenu[0] ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openSubmenu[0]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {userData?.userTypeID !== 0 && (
                  <ListItemButton component="a" href="/profile">
                    <ListItemText primary={t("my_profile")} />
                  </ListItemButton>
                )}
                {userData?.userTypeID !== 0 && (
                  <ListItemButton
                    component="a"
                    href={
                      userData?.userTypeID === 2
                        ? "/profile/organization/activity"
                        : "/profile/activity"
                    }
                  >
                    <ListItemText primary={t("my_activity")} />
                  </ListItemButton>
                )}
                <ListItemButton onClick={() => setOpenLogoutDialog(true)}>
                  <ListItemText primary={t("log_out")} />
                </ListItemButton>
              </List>
            </Collapse>
          </>
        ) : (
          <List>
            {userData?.userTypeID !== 0 && (
              <ListItem>
                <ListItemButton component="a" href="/profile">
                  <ListItemText primary={t("my_profile")} />
                </ListItemButton>
              </ListItem>
            )}
            {userData?.userTypeID !== 0 && (
              <ListItem>
                <ListItemButton
                  component="a"
                  href={
                    userData?.userTypeID === 2
                      ? "/profile/organization/activity"
                      : "/profile/activity"
                  }
                >
                  <ListItemText primary={t("my_activity")} />
                </ListItemButton>
              </ListItem>
            )}
            <ListItem>
              <ListItemButton onClick={() => setOpenLogoutDialog(true)}>
                <ListItemText primary={t("log_out")} />
              </ListItemButton>
            </ListItem>
          </List>
        )}
      </Box>
      <LogoutDialog
        open={openLogoutDialog}
        setOpen={setOpenLogoutDialog}
        logout={logout}
      />
    </div>
  );
};
const NavBar = ({ menu, logo }: any) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const userData = useSelector((state: any) => state.auth.user);
  const USER_AGENT = "ufar-united-app";
  let lang = localStorage.getItem("language");
  let imgPath = fr;
  if (lang) {
    imgPath = lang === "hy" ? hy : lang === "en" ? en : fr;
  } else {
    if (i18n.language !== "fr") i18n.changeLanguage("fr");
  }

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const sessionState = useSelector(
    //@ts-ignore
    (state) => state.auth.session
  );

  const authState = { isAuthenticated: sessionState.signedIn };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(1024));

  const toggleDrawer =
    (state: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setIsDrawerOpen(state);
    };

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <NavLink to="/main">
          <img src={logo} alt="logo" className={classes.logo} />
        </NavLink>
        {!isMobile ? (
          <>
            {" "}
            <Lists
              isDrawerOpen={isDrawerOpen}
              toggleDrawer={toggleDrawer}
              menuItems={menu}
            />
            <div style={{ display: "flex", alignItems: "center" }}>
              <FadeMenu {...{ menuItems, imgPath }} />
              {!authState.isAuthenticated ? (
                <Button
                  className={classes.logInBtn}
                  onClick={() => navigate("/login")}
                >
                  {t("log_in")}
                </Button>
              ) : (
                <UserNameBlock userData={userData} />
              )}
            </div>
          </>
        ) : (
          <>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={isDrawerOpen ? toggleDrawer(false) : toggleDrawer(true)}
              edge="end"
              className={classes.menuButton}
              size="medium"
            >
              {isDrawerOpen ? <CloseIcon /> : <MenuIcon />}
            </IconButton>
            <Drawer
              className={classes.drawer}
              anchor="right"
              variant="temporary"
              open={isDrawerOpen}
              onClose={toggleDrawer(false)}
              style={{ zIndex: 127 }}
            >
              <Lists toggleDrawer={toggleDrawer} menuItems={menu} />
              {!authState.isAuthenticated ? (
                <Button
                  className={classes.mobileLogInBtn}
                  onClick={() => {
                    navigate("/login");
                    setIsDrawerOpen(false);
                  }}
                >
                  {t("log_in")}
                </Button>
              ) : (
                <UserNameBlock userData={userData} />
              )}
              {USER_AGENT !== window.navigator.userAgent && (
                <FadeMenu {...{ menuItems, imgPath }} />
              )}
            </Drawer>
          </>
        )}
      </div>
    </div>
  );
};

export default NavBar;
