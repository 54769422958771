import _ from "lodash";

export const formData = (data: any) => {
  const newData = _.cloneDeep(data);
  const bodyFormData = new FormData();

  for (const key in newData) {
    if (
      Object.prototype.hasOwnProperty.call(newData, key) &&
      newData[key] !== undefined
    ) {
      if (newData[key] instanceof Blob) {
        if (newData[key].type.startsWith("application/pdf")) {
          bodyFormData.append(key, newData[key], "file.pdf");
        } else {
          bodyFormData.append(
            key,
            newData[key],
            Math.round(Math.random() * 10000000000) +
              "." +
              newData[key].type.slice(newData[key].type.indexOf("/") + 1)
          );
        }
      } else if (Array.isArray(newData[key])) {
        newData[key].forEach((i: string | Blob, idx: any) => {
          if (i instanceof Blob) {
            if (i.type.startsWith("application/pdf")) {
              bodyFormData.append(key, i, "file.pdf");
            } else {
              bodyFormData.append(
                key,
                i,
                Math.round(Math.random() * 10000000000) +
                  "." +
                  i.type.slice(i.type.indexOf("/") + 1)
              );
            }
          } else if (typeof i !== "object") {
            bodyFormData.append(key + `[${idx}]`, i);
          }
        });
      } else {
        bodyFormData.append(key, newData[key]);
      }
    }
  }

  return bodyFormData;
};
